$(function(){

  //init
  modalVideo();
  modalInline();


  //閉じるボタン生成
  function makeCloseBtn() {
    if (!$('.mfp-wrap').find('.c-modal__close').length) {
      $('.mfp-wrap').append('<a href="#" class="c-modal__close"></a>');
    }
    //closeボタン動作
    $('.c-modal__close').on('click', function(e) {
      e.preventDefault();
      $.magnificPopup.close();
    });
  }


  //youtube動画表示
  function modalVideo() {
    $('.c-modal__btn--movie').magnificPopup({
      type: 'iframe',
      mainClass: "c-modal__wrap",
      closeOnBgClick: true,
      showCloseBtn: false,
      callbacks: {
        open: function() {
          makeCloseBtn();
        },
        close: function() {}
      }
    });
  }


  //inline表示
  function modalInline() {
    $('.c-modal__btn--inline').magnificPopup({
      type: 'inline',
      preloader: false,
      mainClass: "c-modal__wrap",
      closeOnBgClick: true,
      showCloseBtn: false,
      callbacks: {
        open: function() {
          makeCloseBtn();
        },
        close: function() {}
      }
    });
  }


});